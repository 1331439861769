import { default as about_45passjNkOLqt70kMeta } from "/codebuild/output/src2901992462/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviA5ec9nOsTKMeta } from "/codebuild/output/src2901992462/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementYoFceCSKR1Meta } from "/codebuild/output/src2901992462/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliasNNW6DTbLYHMeta } from "/codebuild/output/src2901992462/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmmINhZL0iJzMeta } from "/codebuild/output/src2901992462/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliascjbzrEj8H8Meta } from "/codebuild/output/src2901992462/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editP1p2yQmFPvMeta } from "/codebuild/output/src2901992462/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexjKbDrxOb12Meta } from "/codebuild/output/src2901992462/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmcjWWaVWWvTMeta } from "/codebuild/output/src2901992462/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexQuYWBHyokjMeta } from "/codebuild/output/src2901992462/src/src/pages/card/create/index.vue?macro=true";
import { default as indexJCxHx413cAMeta } from "/codebuild/output/src2901992462/src/src/pages/card/index.vue?macro=true";
import { default as completeNrx07m4qovMeta } from "/codebuild/output/src2901992462/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmnlMh9thC5mMeta } from "/codebuild/output/src2901992462/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerXjMkH9daa3Meta } from "/codebuild/output/src2901992462/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactxPmezXfi8nMeta } from "/codebuild/output/src2901992462/src/src/pages/contact.vue?macro=true";
import { default as _91id_93XviNZf07SqMeta } from "/codebuild/output/src2901992462/src/src/pages/coupon/[id].vue?macro=true";
import { default as indexZR3e2F2p6NMeta } from "/codebuild/output/src2901992462/src/src/pages/coupon/index.vue?macro=true";
import { default as faqxGnjxyrdUpMeta } from "/codebuild/output/src2901992462/src/src/pages/faq.vue?macro=true";
import { default as indexmyRNEKW5fMMeta } from "/codebuild/output/src2901992462/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93LhqfF7wwmJMeta } from "/codebuild/output/src2901992462/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexISo843v44rMeta } from "/codebuild/output/src2901992462/src/src/pages/index.vue?macro=true";
import { default as maintenance2k4wuHtRfHMeta } from "/codebuild/output/src2901992462/src/src/pages/maintenance.vue?macro=true";
import { default as completeAzb6S9Oh3DMeta } from "/codebuild/output/src2901992462/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmRmJ7tPcnmgMeta } from "/codebuild/output/src2901992462/src/src/pages/membership/confirm.vue?macro=true";
import { default as register3FxfcO7pFtMeta } from "/codebuild/output/src2901992462/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codef7Axqba5rCMeta } from "/codebuild/output/src2901992462/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmc9cU0K48eWMeta } from "/codebuild/output/src2901992462/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerWgaUqmFuYvMeta } from "/codebuild/output/src2901992462/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuySLpmRY2W1Meta } from "/codebuild/output/src2901992462/src/src/pages/menu.vue?macro=true";
import { default as indexwLeB8ySW75Meta } from "/codebuild/output/src2901992462/src/src/pages/mypage/index.vue?macro=true";
import { default as indexmeYU8TtumIMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as samplevidVViWhLOMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completej3eRIJIoMkMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeA9cu2XblfMMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexS3zlW3WkYWMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexGdz33iSi0qMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completenO4CjpL0GNMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmlDOzTKPfSnMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexwHHtGStjTXMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updateYttNaNJqkXMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmpPnCKuAtNdMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexRWI12g5KINMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updatexpGWvdAAMgMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeu1Fpqf7UGhMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as update7AilKlSS1IMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexVaCPXbbRyZMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93bihEzy92YBMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeyigA0rm19ZMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmXKKtZRMvGYMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completeszzDmuaGKuMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmRQSWBwUdj3Meta } from "/codebuild/output/src2901992462/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentpihHfKejpzMeta } from "/codebuild/output/src2901992462/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93f7JueiD8jiMeta } from "/codebuild/output/src2901992462/src/src/pages/notice/[id].vue?macro=true";
import { default as indexgAMbH2ZAbmMeta } from "/codebuild/output/src2901992462/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93vURzPdcDtiMeta } from "/codebuild/output/src2901992462/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementx6UbYLkJK9Meta } from "/codebuild/output/src2901992462/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeyBGSRJu57sMeta } from "/codebuild/output/src2901992462/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeORvekwyHTvMeta } from "/codebuild/output/src2901992462/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordXCU8SPeq5CMeta } from "/codebuild/output/src2901992462/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emaillu4nDyHY1QMeta } from "/codebuild/output/src2901992462/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeldVkEoxA2nMeta } from "/codebuild/output/src2901992462/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexHjPgQf5hOVMeta } from "/codebuild/output/src2901992462/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codex1Q4xMAiJeMeta } from "/codebuild/output/src2901992462/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexNvc4dAfkVLMeta } from "/codebuild/output/src2901992462/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialhXs9psZhn4Meta } from "/codebuild/output/src2901992462/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliasNNW6DTbLYHMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmmINhZL0iJzMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliascjbzrEj8H8Meta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editP1p2yQmFPvMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmcjWWaVWWvTMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexQuYWBHyokjMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeNrx07m4qovMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmnlMh9thC5mMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerXjMkH9daa3Meta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexZR3e2F2p6NMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexmyRNEKW5fMMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexISo843v44rMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeAzb6S9Oh3DMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmRmJ7tPcnmgMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: register3FxfcO7pFtMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codef7Axqba5rCMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmc9cU0K48eWMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerWgaUqmFuYvMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuySLpmRY2W1Meta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexwLeB8ySW75Meta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexmeYU8TtumIMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: samplevidVViWhLOMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexVaCPXbbRyZMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeyigA0rm19ZMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmXKKtZRMvGYMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completeszzDmuaGKuMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmRQSWBwUdj3Meta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentpihHfKejpzMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexgAMbH2ZAbmMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93vURzPdcDtiMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeyBGSRJu57sMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeORvekwyHTvMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordXCU8SPeq5CMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emaillu4nDyHY1QMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeldVkEoxA2nMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexHjPgQf5hOVMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codex1Q4xMAiJeMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexNvc4dAfkVLMeta || {},
    component: () => import("/codebuild/output/src2901992462/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2901992462/src/src/pages/tutorial.vue")
  }
]